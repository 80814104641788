import React from "react"
import LayoutEng from "../../components/LayoutEng"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../../components/SEO"

export default function Home({data}) {
  console.log(data)
  const services = data.service.nodes
  const blogEng = data.blogsEng.nodes

  return (
    <LayoutEng>
      <SEO title="Home" description="Discover compassionate online and in-person counseling for expats and those facing life transitions. Accessible in English and Croatian in Novi Zagreb." />
      <main className="page intro-page">
        <header  className="intro">
          <div id="intro_text">              
            <h1 className="apsihaNaslov"><strong>Let's unfold your story </strong></h1> 
              <div className="hidden-lg"><StaticImage
              src="../../assets/images/origami_hero_mob.png"
              alt="Origami_boat"
              className="boat_mob"
              placeholder="DOMINANT_COLOR"
              //layout="constrained"
              ></StaticImage>
              </div>
              <p>Creases and wrinkles are a part of life design.</p>
              <p>That is why we wanted to ensure you are receiving committed, culturally sensitive, empathetic and authentic help.</p>
              <p>We are advocates and partners on your journey  who will wholeheartedly be in your corner and cheer you on.</p>
              <div>           
              <Link to="/en/Contact" className="btn">
              Contact us today
              </Link>
              </div>
          </div>
          </header>

          <div className="whiteSection boxedWhite" id="services">
          <div className="whiteSection-container">
            <div className="whiteSection-text">
            <h2 className="center"><strong>Our Services</strong></h2>
            <div className="tags-list" id="tagsEng">
            {services.map(services =>( 
              <Link to={"../../en/Services/" + services.frontmatter.slug} key={services.id}>
                <div className="tagThumb">
                <GatsbyImage
                  image={getImage(services.frontmatter.thumb.childImageSharp.gatsbyImageData)}
                  alt={services.frontmatter.title}
                  />
                  <div className="tagDescript">
                  <h3><strong>{services.frontmatter.title}</strong></h3>
                  <p>{services.frontmatter.showDescription}</p>
                  </div>
                </div>
              </Link>
            ))}
        </div>
          </div>
          </div>
        </div>
        <div className="blueSection boxedBlue" id="blue01">
          <div className="blueSection-container">
            <div className="blueSection-text">
              <div className="blueSection-columns1">
                <div className="boxedB">
                <h2><strong>We're here for you!</strong></h2>
                <p>Our mission was to create a safe space while you navigate sensitive transitions. We are looking forward to the opportunity to support you along the way.</p> 
                <p>We understand that change, as with any other challenge, comes with a great deal of both excitement and emotional strain.</p> 
                <p>Healthy expatriation and repatriation are layered and include various life roles and skill sets. They are also a team effort.</p>
                <p>We will bring extensive expertise, cultural sensitivity, authenticity and genuine care in our work together and support you in</p>
                <ul className="popisX">
                <li> Needs and values exploration and assessment</li>
                <li> Resources and capacity building </li>
                <li> Navigating the transitions, and </li>
                <li> Thriving in your expat adventure</li>
                </ul>
                <p>If you are interested in our areas of expertise and ways we can support you, you can find more information <Link to="/en/About" className="about_link"> here</Link>.</p>
                </div>
                <div>
                <StaticImage
                 src="../../assets/images/Fortuneteller.png"
                  alt="Apsiha_"
                  className="blueSection-img"
                  placeholder="DOMINANT_COLOR"
                  layout="fullWidth"
                  ></StaticImage>
                </div>
              </div>
           </div>
          </div>
        </div>
        <div className="blogSection">
        <h2 className="center"><strong>Read our latest Blog posts</strong></h2>
        <div className="blogs-list" id="blogsEng">
        {blogEng.map(({ frontmatter }) => {
          return (        
              <Link to={"../../blogEng/" + frontmatter.slug} key={frontmatter.id}>
                <div className="BlogThumb boxedIn">
                  <GatsbyImage
                  image={getImage(frontmatter.thumb.childImageSharp.gatsbyImageData)}
                  alt={frontmatter.title}
                  />
                  <div className="BlogDescript">
                  <h4><strong>{frontmatter.title}</strong></h4>
                  <p>{frontmatter.description}</p>
                  <p className="kurziv">by: {frontmatter.author}, {frontmatter.date}</p>
                  </div>
                </div>
              </Link>
          )
        })}
      </div>
        </div>
      </main>
    </LayoutEng>
  )
}

export const query = graphql`
query Services {
    service: allMarkdownRemark(
      sort: {fields: frontmatter___order, order: ASC}
      filter: {frontmatter: {type: {eq: "services_input"}}}
    ) {
      nodes {
        frontmatter {
          slug
          order
          title
          description
          showDescription
          thumb {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                transformOptions: {fit: CONTAIN}
                width: 450
              )
            }
          }
        }
        id
      }
    }
   blogsEng: allMarkdownRemark(
      limit: 3
      sort: {fields: frontmatter___date, order: DESC}
      filter: {frontmatter: {type: {eq: "blogEng_input"}}}
    ) {
      totalCount
      nodes  {
        frontmatter {
          slug
          title
          date
          description
          author
          id
          thumb {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                transformOptions: {fit: CONTAIN}
                width: 450
              )
            }
          }
        }
        id
      }
    }
  }
`